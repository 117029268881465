<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-input class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
          </div>
        </div>
        
        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="supplierList"
          :gridOptions="gridOptions"
          rowSelection="single"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
        >
        </ag-grid-vue>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
// import CellRendererActions from './cell-renderers/CellRendererActions.vue'
import Vue from "vue"
import TravioAccessControl from '@/components/travio-pro/TravioAccessControl.vue';
import CellRendererCredentialValidity from './cell-renderers/CellRendererCredentialValidity.vue'
import CellRendererSupplierActions from './cell-renderers/CellRendererSupplierActions.vue'
import CellRendererCheckbox from './cell-renderers/CellRendererCheckbox.vue'
import CellRendererIsSharedCheckbox from './cell-renderers/CellRendererIsSharedCheckbox.vue'

import { format } from 'date-fns'

export default {
  props: {
    applicationId: { required: true }
  },
  components: {
    AgGridVue,
    CellRendererCredentialValidity,
    CellRendererSupplierActions,
    TravioAccessControl,
    CellRendererCheckbox,
    CellRendererIsSharedCheckbox
  },
  data () {
    return {
      supplierList: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererSupplierActions,
        CellRendererCredentialValidity,
        CellRendererCheckbox,
        CellRendererIsSharedCheckbox
      },
      searchQuery: '',
      showDeleteRolePopup: false,
      selectedReplacementRole: {}
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  created () {
    //Check if user has acccess to the company
    const hasAccess = this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)
    if(!hasAccess) {
      this.$router.push('/error-404')
      return
    }

    this.$vs.loading()
    this.$http.get(`api/supplierIntegrations/apps/${this.applicationId}/mysuppliers`)
    .then(response => {
        this.supplierList = response.data
    })
    .catch(error => console.error(error))
    .finally(() => this.$vs.loading.close())  
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: '', width: 70, sortable: false, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererSupplierActions) },
      { field: 'threadId', hide: true },
      { field: 'supplierId', hide: true },
      { field: 'isEditable', hide: true },
      { headerName: 'Supplier Name', field: 'supplierName', width: 270, suppressSizeToFit: false, filter: true },
      { headerName: 'Description', field: 'description', width: 270, suppressSizeToFit: false, filter: true },
      { headerName: 'Product Type', field: 'productType', width: 200, suppressSizeToFit: false, filter: true },
      { headerName: 'API Type', field: 'apiType', width: 180, suppressSizeToFit: false, filter: true },
      { headerName: '', sortable: true, suppressSizeToFit: true, width: 60, cellRendererFramework: Vue.extend(CellRendererCredentialValidity) },
      { headerName: 'Enabled', field: 'isActive', sortable: true, suppressSizeToFit: false, width: 140, cellRendererFramework: Vue.extend(CellRendererCheckbox) },
      { headerName: 'Shared', field: 'isShared', sortable: true, suppressSizeToFit: false, width: 140, cellRendererFramework: Vue.extend(CellRendererIsSharedCheckbox) },
      { 
        headerName: 'Created Date',
        field: 'createdDate',
        sortable: true,
        suppressSizeToFit: true,
        width: 210,
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat)
      },
      { 
        headerName: 'Updated Date',
        field: 'updatedDate',
        sortable: true,
        suppressSizeToFit: true,
        width: 210,
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat)
      }
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 70,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    }
  }
}
</script>

<style>

</style>
