<template>
  <div v-if="params.data.isEditable">
    <feather-icon disabled title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onEdit" />
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CellRendererSupplierActions',
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    onEdit () {
      this.$router.push({ name: 'supplier-integrations-edit', 
        params: { 
          integrationId: this.params.data.threadId,
          supplierId: this.params.data.supplierId,
        }
      })
    }
  }
}
</script>
